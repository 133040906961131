var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"grid grid-cols-8 gap-6 relative left-1/2 right-1/2 -ml-48vw -mr-48vw max-w-96vw"},_vm._l((_vm.sorted),function(item,index){return _c('div',{key:index,class:[
          {'col-span-full row-auto md:row-span-1 md:col-span-3': (index % 8 == 0)},
          {'col-span-full row-auto md:row-span-2 md:col-span-5': (index % 8 == 1)},
          {'col-span-full row-auto md:row-span-1 md:col-span-3': (index % 8 == 2)},
          {'col-span-full row-auto md:row-span-2 md:col-span-4': (index % 8 == 3)},
          {'col-span-full row-auto md:row-span-1 md:col-span-2': (index % 8 == 4)},
          {'col-span-full row-auto md:row-span-1 md:col-span-2': (index % 8 == 5)},
          {'col-span-full row-auto md:row-span-1 md:col-span-2': (index % 8 == 6)},
          {'col-span-full row-auto md:row-span-1 md:col-span-2': (index % 8 == 7)},
          'min-h-24'
        ]},[_c('router-link',{attrs:{"to":{ name: 'Single', params: { slug: item.slug }}}},[(item.featured.image.formats.large)?_c('img',{staticClass:"object-cover w-full",attrs:{"src":_vm.api + item.featured.image.formats.large.url,"alt":item.featured.image.alternativeText}}):(item.featured.image.formats.medium)?_c('img',{staticClass:"object-cover w-full",attrs:{"src":_vm.api + item.featured.image.formats.medium.url,"alt":item.featured.image.alternativeText}}):(item.featured.image.formats.small)?_c('img',{staticClass:"object-cover w-full",attrs:{"src":_vm.api + item.featured.image.formats.small.url,"alt":item.featured.image.alternativeText}}):_c('img',{staticClass:"object-cover w-full",attrs:{"src":_vm.api + item.featured.image.url,"alt":item.featured.image.alternativeText}})])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }