<template>
<div>
  <h1>{{ title }}</h1>
  <div class="grid grid-cols-8 gap-6 relative left-1/2 right-1/2 -ml-48vw -mr-48vw max-w-96vw">
      <div 
        v-for="(item, index) in sorted" 
        :key="index"
        :class="[
          {'col-span-full row-auto md:row-span-1 md:col-span-3': (index % 8 == 0)},
          {'col-span-full row-auto md:row-span-2 md:col-span-5': (index % 8 == 1)},
          {'col-span-full row-auto md:row-span-1 md:col-span-3': (index % 8 == 2)},
          {'col-span-full row-auto md:row-span-2 md:col-span-4': (index % 8 == 3)},
          {'col-span-full row-auto md:row-span-1 md:col-span-2': (index % 8 == 4)},
          {'col-span-full row-auto md:row-span-1 md:col-span-2': (index % 8 == 5)},
          {'col-span-full row-auto md:row-span-1 md:col-span-2': (index % 8 == 6)},
          {'col-span-full row-auto md:row-span-1 md:col-span-2': (index % 8 == 7)},
          'min-h-24'
        ]"
      >
        <router-link :to="{ name: 'Single', params: { slug: item.slug }}">
          <img class="object-cover w-full" v-if="item.featured.image.formats.large" :src="api + item.featured.image.formats.large.url" :alt="item.featured.image.alternativeText"/>
          <img class="object-cover w-full" v-else-if="item.featured.image.formats.medium" :src="api + item.featured.image.formats.medium.url" :alt="item.featured.image.alternativeText"/>
          <img class="object-cover w-full" v-else-if="item.featured.image.formats.small" :src="api + item.featured.image.formats.small.url" :alt="item.featured.image.alternativeText"/>
          <img class="object-cover w-full" v-else :src="api + item.featured.image.url" :alt="item.featured.image.alternativeText"/>
        </router-link>
      </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'Grid',
  props: ['data'],
  metaInfo() {
    return {
      title: this.title
    }
  },
  data() { 
    return {
      api: process.env.VUE_APP_API_URL
    }
  },
  computed: {
    title() {
      return this.$options.filters.titleize(this.$route.params.type)
    },
    sorted() {
      const arr = this.data
      return arr.sort((a, b) => (a.slug > b.slug) - (a.slug < b.slug)).reverse()
    }
  }
}
</script>

<style scoped>
img {
  height: 100%!important
}
</style>